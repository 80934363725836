import { render, staticRenderFns } from "./Foreign.vue?vue&type=template&id=2aa19c50&scoped=true&"
import script from "./Foreign.vue?vue&type=script&lang=js&"
export * from "./Foreign.vue?vue&type=script&lang=js&"
import style0 from "./Foreign.vue?vue&type=style&index=0&id=2aa19c50&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa19c50",
  null
  
)

export default component.exports